@font-face {
  font-family: 'CustomFont';
  src: url('/public/fonts/d2b73733f3549d7d-s.p.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

:root {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
}

* {
  font-family: 'CustomFont', sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}

#optional {
  font-weight: 500;
  color: rgb(189 189 189);
  line-height: 1.5rem;
  text-shadow : 0 0 0 black !important;

}

.custom-thumb {
  margin-left: -10px; /* Half the thumb's width */
  margin-right: -10px; /* Half the thumb's width */
}



.maplibregl-ctrl-attrib {
  display: none !important;
}


.tshad {
  text-shadow : 0 0 0 black !important;
}

.tshad2 {
  text-shadow : 0 0 0 #9e9e9e !important;
}

.tshad3 {
  text-shadow : 0 0 0 #757575 !important;
}


.activityCards {
  color: rgb(189 189 189);
}

.customgray2 {
  color: rgb(117 117 117) !important;
  text-shadow : 0 0 0 rgb(117 117 117) !important;
}

.customgray {
  color: rgb(189 189 189) !important;
}

#shadow {
  text-shadow : 0 0 0 #ffffff !important;
}


#fonts {
  color: black !important;
  font-weight: 500;
  line-height: 1.75rem;
  font-size: 1.125rem !important;
  text-shadow : 0 0 0 black !important;

}

h1, h2, h3, h4, h5 {
  color: black !important;
  font-weight: 500 !important;
  text-shadow : 0 0 0 black !important;

}

.signin {
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow) !important;
}

.normalWeight {
  font-weight: normal !important;
}

div[class*="--SearchBox"] {
  font-size: 1rem; /* Set your desired font size */
  box-shadow: none !important;
  border: 1px solid #E2E8F0 !important;
}

input[class*="--Input"] {
  border-radius: 0.375rem;
  border: 0px solid black !important;
}

.rs-input {
  font-size: 1rem !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Adjusting the size and shape to make the indicators smaller and more circular */
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  width: 8px; /* Makes the indicator smaller */
  height: 8px; /* Makes the indicator smaller */
  border-radius: 50%; /* Gives a circular shape */
  background-color: #cccccc; /* Light grey for inactive state; adjust as needed */
  margin-top: 0.5rem;
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.tshadbtn {
  text-shadow : 0 0 0 rgb(158 158 158) !important;
}

/* Adjusting the color for active state */
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight {
  background-color: rgba(163,143,252,.65); /* Teal for active state; adjust as needed */
  width: 11px; /* Makes the indicator smaller */
  height: 11px; /* Makes the indicator smaller */
}

/* Adding hover effect for indicators */
.p-carousel .p-carousel-indicators .p-carousel-indicator:hover {
  background-color: rgba(163,143,252,.65); /* Teal on hover; adjust if different from active state is desired */
}
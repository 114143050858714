:root{--rts-primary-color:
    #f5f5f5;--rts-gray-color:
    #ddd;--rts-white-color:#fff}
.display___block{
    display:block
}

.display____inline___block{
    display:inline-block

}.display___none{
    display:none!important

}@media(max-width:991.98px){
    .display___md___none{
        display:none!important
    }}
    .rts___tabs___container{
        display:flex;overflow:auto
    }
    @media
    (max-width:991.98px){
        .rts___tabs___container{
            padding:5px
        }}
        .rts___tabs{
            display:flex;overflow:auto;padding:10px 0
        }
.rts___tabs.hide___rts___tabs___scroll{
    -ms-overflow-style:none;
    scrollbar-width:none
}

.rts___tabs.hide___rts___tabs___scroll::-webkit-scrollbar{
    display:none

}
.rts___tab{padding:8px
    12px;
    border-radius: 9999px;
    border: 1px solid white;
    font-size: .875rem !important;
    white-space:nowrap;
    vertical-align:middle;
    text-align:center;
    margin:0
    5px;
    text-shadow: 0 0 0 #757575 !important;
    color: #757575;
}

#touract {
    border: 1px solid rgba(255,85,52,.4) !important;
    color: black !important;
}

#touract:hover {
    background: rgba(255,85,52,.1) !important;
}

.rts___tab:hover {
    background-color: var(--rts-primary-color);
    color: #424242;
}

@media(max-width: 991.98px){
    .rts___tab{
        margin:0 2px}}
@media(max-width:767.98px){
    .rts___tab{
        padding:5px 20px}}
    .rts___nav___btn___container{display:flex}.rts___btn{cursor:pointer;background-color:transparent;border: none !important;}.rts___tab___selected{background-color:var(--rts-primary-color);color:#424242;box-shadow:none !important; border-color: 1px solid rgb(224, 224, 224);}.rts___nav___btn{color:#0009;font-size:18px;font-weight:700;display:flex;justify-content:center;align-items:center}.rts___nav___btn:hover{background-color:var(--rts-primary-color);transition:.5s
    all}.rts___nav___btn:hover>svg{stroke:var(--rts-white-color)}@media
    (max-width:
    767.98px){.rts___nav___btn>svg{width:15px}}.rts___svg___icon{stroke:#0009}button:disabled.rts___btn,.rts___nav___btn:disabled,button[disabled].rts___btn{cursor:not-allowed;color:var(--rts-gray-color);pointer-events:none}.rts
    button:disabled.rts___btn
    svg,.rts___nav___btn:disabled
    svg,button[disabled].rts___btn
    svg{stroke:var(--rts-gray-color)}
    
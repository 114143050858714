/* styles/Home.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .title {
    margin-top: 5vw !important;
  }
  
  .inputGroup {
    margin-bottom: 15px;
  }
  
  /* .checkboxGroup {
    display: flex;
    align-items: center; 
     margin-right: 5px;
  } */
    
  .mainText {
    padding-bottom: 2%;
  }

  .generate-btn {
    margin-bottom: 5vw;
  }

  .search-box-input:focus {
    border: none !important;
  }
  
  input[aria-label="Find your destination (Cities only)"] {
    border: none !important;
    box-shadow: none;
  }